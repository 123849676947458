import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Navbar from './Navbar'; // Ensure you have Navbar component
import Sidebar from './Sidebar'; // Ensure you have Sidebar component
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import Loader from './Loader'
import { ToastContainer, toast } from 'react-toastify';
import Footer from './Footer';
let { appname, lambda } = window.app;
const MyDevices = () => {
  const [transactions, setTransactions] = useState([]);
  const [image, setImg] = useState('');
  const [addDevice, setAddDevice] = useState(false)
  const [deviceName, setDeviceName] = useState(null)
  const [deviceNameValue, setDeviceNameValue] = useState(null)
  const [activeLoad, setActiveLoad] = useState(false);
  const [showDocAlert, setShowDocAlert] = useState(false);
  const [deletedAlbum, setDeleteItem] = useState(null);
  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
  }, []);
  useEffect(() => {

    fetchTransactions();
  }, []);
  const fetchTransactions = async () => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/devicesharesList?appname=${appname}&clientid=${clientid}`);
      // setVideos(response.data.result);
      setTransactions(response.data.result);
      
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };
  const noRecordsFound = () => (
    <div className="content-block blankpage allfiles-blankpage">
     
        <div className="text-center transform-center blank-image-fit">

          <img src={`${image}images/app/images/default-img.png`} alt="devices" />

          <h3 className="blankpage-title">NO DEVICES FOUND</h3>
      
      </div>
    </div>
  );
  const columns = [

    { name: 'Type', selector: row => row?.type, sortable: true },
    { name: 'Code', selector: row => row?.code, sortable: true },

    { name: 'Created At', selector: row => formatDate(row?.created), sortable: true },

  ];
  function formatDate(dateString) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;

    const strMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${day}-${month}-${year}, ${hours}:${strMinutes} ${ampm}`;
  }



  const handleAddToDevice = async () => {
    if (!deviceName.trim()) {
      toast.error('Please enter device id.');
      return;
    }
    try {
      setActiveLoad(true)
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      setAddDevice(false)
      const response = await axios.post(
        `${lambda}/deviceShareLinkUpdate?appname=${appname}&clientid=${clientid}${deviceName ? `&code=${deviceName}` : ''}${deviceNameValue ? `&devicename=${deviceNameValue}` : ''}`
      );
      
      setActiveLoad(false)
      console.log('devices added:', response.data);
      fetchTransactions();
      if(response.data.result == "code is invalid"){
        toast.error(`Code is Invalid`);
      }else if(response.data.result == "code already paired"){
        toast.error(`Device already paired`);
      }else{
        toast.success(`Device added successfully!`);
      }
      

    } catch (error) {
      setActiveLoad(false)
      console.error('Error adding to devices:', error);
      toast.error('Failed to add device.');
    }
  };

  const handleDelete = async (deviceId) => {
    setActiveLoad(true)
    setShowDocAlert(false)
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${lambda}/devicesharesList?appname=${appname}&clientid=${clientid}&deviceid=${deviceId}`);
      // setVideos(response.data.result);
      setActiveLoad(false)
      toast.success(`Device deleted successfully!`);
      fetchTransactions();
      setDeleteItem(null)
    } catch (error) {
      setActiveLoad(false)
      toast.error('Failed to delete device.');
      console.error('Error fetching videos:', error);
    }
  };

  return (
    <div id="page-top">
      <Navbar />
      <div className="inner-body">
        <div className="inner-wrapper">
          <Sidebar />
          <div className="right-section">
            {activeLoad && <Loader />}
            <div className="page-title">
              <h1 className="sm-heading lite-text">My Devices</h1>
            </div>

            <div className="content-block blankpage allfiles-blankpage">
              <div className="small-wrapper">
                <div className="Mydevice-header">
                  <div className="Mydevice-title">
                    <h2>Manage Your Devices</h2>
                    <p>These have been active on this account.  You can delete any unfamiliar devices for added security.</p>
                  </div>
                  <button className="btn gradietn_btn rounded-pill" onClick={(e) => setAddDevice(true)} >Add Device<span className="material-symbols-outlined ms-2">add</span></button>
                </div>
                <div className="row">
                  {transactions && transactions?.length > 0 ? transactions.map((item, index) => (
                    <div className="col-md-6">
                      <div className="device-card">
                        <div className="device-card-header">
                          <div className="device-title">

                            <img src={`${image}images/app/images/television.png`} className="me-3" alt="TV" />
                            <h3>{item?.deviceName}</h3>
                          </div>
                          <button className="card-btn" onClick={(e) => {setShowDocAlert(true); setDeleteItem(item.deviceid)}}><span className="material-symbols-outlined">delete</span>Delete</button>
                        </div>
                        <div className="device-card-footer">
                          <p>Code: {item?.code} | {item?.type}</p>
                          <p>Added on  {moment(item?.created).format("MM-DD-YY")} </p>
                        </div>
                      </div>
                    </div>
                  )): noRecordsFound()}

                </div>
              </div>
            </div>

            <Modal className="access-denied delete_popup" show={addDevice}>
              <div className="modal-body">
                <button className="close-btn"><span className="material-icons" onClick={e => setAddDevice(false)}>close</span></button>
                <span className="material-icons delete-icon">tv</span>
                <h3>Add Device</h3>
                <div className="form-group">
                <input type="text" name="deviceid" onChange={(e) => setDeviceNameValue(e.target.value)} className="form-control" placeholder="Enter Device Name" />
                <br/>
                  <input type="text" name="deviceid" onChange={(e) => setDeviceName(e.target.value)} className="form-control" placeholder="Enter Device ID" />
                </div>
                <div className="popup-footer">
                  <button className="fill_btn" onClick={e => handleAddToDevice(e)} > Add </button>
                </div>
              </div>
            </Modal>

            <Modal className="modal fade large_popup delete_popup" show={showDocAlert}>
              <div className="modal-body">
                <button className="close-btn"><span className="material-icons" onClick={e => setShowDocAlert(false)}>close</span></button>
                <span className="material-icons delete-icon">delete_outline</span>
                <h3>Delete</h3>
                <p>This action cannot be undone.</p>
                <p>Are you sure you want to delete Album?</p>
                <div className="popup-footer">
                  <button className="fill_btn" onClick={(e) => handleDelete(deletedAlbum)}> Yes, Delete</button>
                </div>
              </div>
            </Modal>

          
            <ToastContainer />
            <Footer />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default MyDevices;
