import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Navbar from './Navbar'; // Ensure you have Navbar component
import Sidebar from './Sidebar'; // Ensure you have Sidebar component
import moment from 'moment';
import Footer from './Footer';
import Loader from './Loader'
let { appname, lambda } = window.app;
const MyContent = () => {
  const [transactions, setTransactions] = useState(false);
  const [image, setImg] = useState('');

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
  }, []);
  useEffect(() => {
    // Fetch transaction data from your API or service here
    // For demonstration, we'll use a static array of transactions
    // const fetchTransactions = async () => {
    //   // Replace this with your API call
    //   const data = [
    //     { paymentId: '1', status: 'Completed', createdAt: '2024-07-20', typeOfItem: 'Book', transactionType: 'Purchase' },
    //     { paymentId: '2', status: 'Pending', createdAt: '2024-07-21', typeOfItem: 'Subscription', transactionType: 'Renewal' },
    //     { paymentId: '3', status: 'Failed', createdAt: '2024-07-22', typeOfItem: 'Electronics', transactionType: 'Purchase' },
    //   ];
    //   setTransactions(data);
    // };

    fetchTransactions();
  }, []);
  const fetchTransactions = async () => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.post(`${lambda}/transactionInfo?appname=${appname}&clientid=${clientid}`);
      // setVideos(response.data.result);
      setTransactions(response.data.result);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };
  const noRecordsFound = () => (
    
     <div className='content-block blankpage allfiles-blankpage'>
        <div className="text-center transform-center blank-image-fit">

          <img src={`${image}images/app/images/default-img.png`} alt="comingsoon" />

          <h3 className="blankpage-title">
            NO TRANSACTIONS FOUND
          </h3>
        
      </div>
      </div>
    
  );
  const columns = [
    {
      name: 'S.No', selector: (row, index) => index+1, width: '10%'
    },
    {
      name: 'Payment ID', selector: row => row.trasactionId
      , sortable: true,  width: '23%'
    },
    { name: 'Storage', selector: row => row?.storage + ' GB', sortable: true, width: '13%'},
    { name: 'Plan', selector: row => row?.planType?.toUpperCase(), sortable: true, width: '12%'},
    { name: 'Amount', selector: row => row?.grandTotal, sortable: true, width: '15%' },
    { name: 'Transaction Type', selector: row => row?.paymentMethodTypes?.toString().toUpperCase(), sortable: true, width: '15%' },
    { name: 'Transaction On', selector: row => moment(row?.created).format("MM-DD-YY"), sortable: true, width: '12%',},
  ];


  return (
    <div id="page-top">
      <Navbar />
      <div className="inner-body">
        <div className="inner-wrapper">
          <Sidebar />
          <div className="right-section">
            <div className="page-title">
              <h1 className="sm-heading lite-text">Transaction History</h1>
            </div>
          
            <div className="content-block blankpage">
              
                {transactions && transactions.length > 0 ?
                  <DataTable
                    columns={columns}
                    data={transactions}
                    // noDataComponent={noRecordsFound()}
                    pagination
                    className="transaction-history-table"
                  />: transactions && transactions.length == 0 ? noRecordsFound()
                : <Loader/>
                }
               
            </div>
            <Footer/>
          </div>

        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default MyContent;
