import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useNavigate } from 'react-router-dom';


const InsideHeader = (props) => {

  const navigate = useNavigate();
  const [image, setImg] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
    loadTheme()
  }, []);

  const loadTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    const rootElem = document.documentElement;
    // If there's a saved theme, apply it
    if (savedTheme) {
      rootElem.setAttribute('data-theme', savedTheme);
      console.log(`Loaded saved theme: ${savedTheme}`);
    } else {
      // If no saved theme, set a default theme
      rootElem.setAttribute('data-theme', 'light');
      console.log('No saved theme found, setting default theme: light');
    }
  }
  const handleClose = () => {
    // if(props?.flag){
    //     navigate('/dashboard');  
    // }else{
    //     navigate('/');
    // } 
    if (location.pathname === "/signin" || location.pathname === "/faq" || location.pathname === "/privacy" || location.pathname === "/terms") {
      navigate('/');
    } else {
      navigate(-1);

    }
  };
  const handleClick = () => {
    navigate("/")
  }
  return (


    <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
      <div className="container-fluid">
        <a className="navbar-brand fw-bold" onClick={handleClick}> 
        <img src={`${image}images/app/images/logo-dark1.png`} className="brand-img lightlogo" alt="Logo" />
        <img src={`${image}images/app/images/logo-white1.png`} className="brand-img darklogo" alt="Logo" />
         </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          Menu
          <i className="bi-list"></i>
        </button>

        <button className="gen-close" onClick={handleClose}>
          {/* <span className="small">Close</span> */}
          <span className="material-symbols-outlined icon">close_small</span>
        </button>
      </div>
    </nav>
  )

};

export default InsideHeader;

