import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader'
let { appname, lambda } = window.app;

let deviceCall = true
const QRCode = () => {
    const { code, name } = useParams();
    const navigate = useNavigate();
    const [activeLoad, setActiveLoad] = useState(false);
    const [image, setImg] = useState('');

    // useEffect(() => {
    //     const checkSiteObject = () => {
    //         if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
    //             setImg(window.site.common.imageCloudfront);

    //         } else {
    //             setTimeout(checkSiteObject, 50);
    //         }
    //     };
    //     checkSiteObject();
    // }, []);



    useEffect(() => {
        if (!localStorage.getItem("token")) {
            localStorage.setItem('c', code)
            localStorage.setItem('n', name)
            navigate("/");
        } else {
            if (deviceCall) {
                deviceCall = false
                handleAddToDevice();
            }
        }
    }, []);

    const handleAddToDevice = async () => {
        if (!name.trim()) {
            toast.error('Invalid Device Name');
            return;
        }
        if (!code.trim()) {
            toast.error('Invalid Code');
            return;
        }
        try {
            setActiveLoad(true)
            const clientid = localStorage.getItem('clientid');
            const token = localStorage.getItem('token');

            const response = await axios.post(
                `${lambda}/deviceShareLinkUpdate?appname=${appname}&clientid=${clientid}${code ? `&code=${code}` : ''}${name ? `&devicename=${name}` : ''}`
            );

            setActiveLoad(false)
            if (response.data.result == "code is invalid") {
                toast.error(`Code is Invalid`);
            } else if (response.data.result == "code already paired") {
                toast.error(`Device already paired`);
            } else {
                toast.success(`Device added successfully!`);
                setTimeout(() => {
                    navigate("/mydevices");
                },2000)
                
            }


        } catch (error) {
            setActiveLoad(false)
            console.error('Error adding to devices:', error);
            toast.error('Failed to add device.');
        }
    };

    return (
        <div id="page-top">
            <div className="inner-body">
                {activeLoad && <Loader />}
            </div>
            <ToastContainer />
        </div>

    )
}



export default QRCode;
