import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    const d = new Date();
    let year = d.getFullYear();

    return (

       
        
        <footer className="text-center py-5">
            <div className="container px-5">
                <div className="text-black-50 small">
                    <div className="mb-2">&copy; Share Clix {year}. All Rights Reserved.</div>
                    <a
                       
                        onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            handleClick('/privacy'); // Navigate to privacy page
                        }}
                    >
                        Privacy
                    </a>
                    <span className="mx-1">&middot;</span>
                    <a
                        
                        onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            handleClick('/terms'); // Navigate to terms page
                        }}
                    >
                        Terms
                    </a>
                    <span className="mx-1">&middot;</span>
                    <a
                        
                        onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            handleClick('/refund-policy'); // Navigate to refund-policy page
                        }}
                    >
                       Refund Policy
                    </a><span className="mx-1">&middot;</span>
                    <a
                        
                        onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            handleClick('/contactus'); // Navigate to FAQ page
                        }}
                    >
                        Contact US
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
