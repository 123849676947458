import React from 'react';
// import ComingSoon from './ComingSoon';
import InsideHeader from './InsideHeader';
import Footer from './Footer';
let { appname, lambda } = window.app;

const About = () => {

  return (
    // <ComingSoon image={image}/>
    <div id="page-top">
      <InsideHeader flag={true} />

      <div className="inner-body contact_us about_us">

<div className="breadcrumb-area-bg bg_image">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="bread-crumb-area-inner">

                    <div className="bottom-title">
                        <h1 className="title">About Us</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div className="about-top-history-information rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-lg-4 col-md-4">
                <div className="title-style-left">
                    <div className="pre-title-area">
                        <img src=" https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about_bar.png" alt="about" />
                        <span className="pre-title">Our Project</span>
                    </div>

                </div>
                <div className="title-style-left out_mission">
                    <div className="pre-title-area">
                        <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about_bar.png" alt="about" />
                        <span className="pre-title">Who We Are</span>
                    </div>

                </div>
                <div className="about-left-history pl--50 pt--30">
                    <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about_arrow.png" alt="about" />
                </div>
                <div class="history-image-left mt--55 rts-reveal-one">
                    <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about-first-right2.png" alt="about" />
                </div>
            </div>
            <div className="col-lg-8 col-md-8 mt_sm--50">
                <div className="history-right-area-inner">
                    <p className="disc rts-slide-up">
                        Welcome to shareclix, a platform designed to celebrate life’s moments by making it easy to upload, organize, and share your personal photos and videos with friends and family. Whether it’s your latest travel adventure, a cherished family gathering, or everyday moments, we provide a safe and seamless space to preserve your memories and share them with the people who matter most.
                    </p>
                    <p className="disc rts-slide-up our_mission_simple">
                        shareclix is a proud product of Sanchan Info Solutions Private Ltd, a global leader in innovative digital solutions. With over 15 years of expertise, Sanchan Info has built a reputation for excellence, delivering cutting-edge solutions across industries such as OTT applications, Real Estate, e-Commerce, Cloud Migration, AWS, Digital Transformation Services, and Mobile and Smart TV apps.
                    </p>



                    <div className="history-right-img mt--80 rts-reveal-one">
                        <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about-first-right1.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<div className="rts-team-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 text-center">
                <div className="title-style-center">

                    <h2 className="title">Our Mission</h2>
                    <p className="disc">
                        At Sanchan Info Solutions Private Ltd, our mission is simple<br />To help you capture, cherish, and connect.<br />We aim to provide an intuitive and secure platform where your memories can thrive, allowing you to share life's most meaningful moments with ease and creativity.
                    </p>
                </div>
            </div>
        </div>

    </div>
</div>


<div className="our-working-process rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="title-process-stock-text">
                    <h2 className="stock-text-1">
                        WHY CHOOSE US?
                    </h2>
                </div>
            </div>
        </div>
        <div className="row g-5 separetor-process-top rts-slide-up">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 pt--50 pt_sm--30 pl-0 pr-10">

                <div className="single-working-prcess-one seamless_experience">
                    <div className="inner">
                        <span>01</span>
                        <h4 className="title">
                            Seamless Experience

                        </h4>
                        <p className="disc">
                            Our user-friendly interface makes uploading, organizing, and sharing content effortless.
                        </p>
                    </div>
                </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 pt--50 pt_sm--30 pl-10 pr-10">

                <div className="single-working-prcess-one advance_technology">
                    <div className="inner">
                        <span>02</span>
                        <h4 className="title">
                            Advanced Technology
                        </h4>
                        <p className="disc">
                            Built using cutting-edge solutions from Sanchan Info Solutions Private Ltd, shareclix.com is powered by expertise in cloud migration, AWS, and mobile app development.
                        </p>
                    </div>
                </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 pt--50 pt_sm--30 pl-10 pr-10">

                <div className="single-working-prcess-one global_reality">
                    <div className="inner">
                        <span>03</span>
                        <h4 className="title">
                            Global Reliability
                        </h4>
                        <p className="disc">
                            With roots in the USA and India, we bring the best of Global Innovation to your fingertips.
                        </p>
                    </div>
                </div>

            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 pt--50 pt_sm--30 pl-10 pr-0">

                <div className="single-working-prcess-one privacy_security">
                    <div className="inner">
                        <span>04</span>
                        <h4 className="title">
                            Privacy and Security
                        </h4>
                        <p className="disc">
                            Your memories are safe with us, thanks to robust Privacy Settings and Secure Data Storage.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<div className="service-area-start rts-service-area rts-section-gap bg-inner-light">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="service-full-top-wrapper rts-slide-up">

                    <div className="title-style-left">

                        <h2 className="title">The Sanchan Info<br /> Solutions Advantage</h2>
                    </div>

                    <p className="disc mb-0">
                        For over 15 years, Sanchan Info Solutions Pvt Ltd. has been at the forefront of digital innovation, serving clients worldwide across multiple domains
                    </p>
                    <a href="#" className="rts-read-more-circle-btn under-line">
                        <span className="material-symbols-outlined"> north_east </span>
                        <p>View All Services</p>
                    </a>
                </div>
            </div>
        </div>

        <div className="row mt--20 g-5 rts-slide-up">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">

                <div className="single-service-style-five">
                    <div className="icon-area">
                    <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/ott.png" alt="" />
                    </div>
                    <div className="body">
                        <a href="#">
                            <h6 className="title">OTT Applications</h6>
                        </a>
                        <p className="disc">
                            Expertise in building top-tier streaming platforms.
                        </p>
                        <a href="#">Read More <span className="material-symbols-outlined">chevron_right</span></a>
                    </div>
                </div>

            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">

                <div className="single-service-style-five">
                    <div className="icon-area">
                    <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/real-esatate.png" alt="" />
                    </div>
                    <div className="body">
                        <a href="#">
                            <h6 className="title">Real Estate Apps</h6>
                        </a>
                        <p className="disc">
                            Innovative solutions for real estate management and transactions.
                        </p>
                        <a href="#">Read More <span className="material-symbols-outlined">chevron_right</span></a>
                    </div>
                </div>

            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">

                <div className="single-service-style-five">
                    <div className="icon-area">
                    <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/e-commerce.png" alt="" />
                    </div>
                    <div className="body">
                        <a href="#">
                            <h6 className="title">E-commerce Apps</h6>
                        </a>
                        <p className="disc">
                            Custom platforms tailored for seamless online shopping experiences.
                        </p>
                        <a href="#">Read More <span className="material-symbols-outlined">chevron_right</span></a>
                    </div>
                </div>

            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">

                <div className="single-service-style-five">
                    <div className="icon-area">
                    <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/aws.png" alt="" />
                    </div>
                    <div className="body">
                        <a href="#">
                            <h6 className="title">Cloud Migration & AWS</h6>
                        </a>
                        <p className="disc">
                            Scalable, reliable cloud solutions for businesses of all sizes.
                        </p>
                        <a href="#">Read More <span className="material-symbols-outlined">chevron_right</span></a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<div className="rts-team-area rts-section-gap join_today">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 text-center">
                <div className="title-style-center">

                    <h2 className="title">Join Us Today!</h2>
                    <p className="disc">
                        Start uploading, organizing, and sharing your stories on shareclix.app—a platform built with love and expertise by Sanchan Info Solutions Pvt Ltd.. Together, let’s turn your moments into memories and your memories into stories that last a lifetime.

                    </p>
                </div>
            </div>
        </div>

    </div>
</div>


<div className="footer_strip">
    <div className="container">
        <div>
            <h4>Have questions? Get in touch at [contact@shareclix.com]. Let’s make memories, together.</h4>
        </div>

    </div>
</div>



<Footer />
</div>
      {/* <div className='content_block'>
        <div className='content'>
          <h1>About Us</h1>
          <p>Welcome to Share Clix, a platform designed to celebrate life’s moments by making it easy to upload, organize, and share your personal photos and videos with friends and family. Whether it’s your latest travel adventure, a cherished family gathering, or everyday moments, we provide a safe and seamless space to preserve your memories and share them with the people who matter most.</p>
          <h3>Who We Are</h3>
          <p>Share Clix is a proud product of FusionShift Technologies, a global leader in innovative digital solutions. With over 15 years of expertise, FusionShift Technologies has built a reputation for excellence, delivering cutting-edge solutions across industries such as OTT applications, real estate, e-commerce, cloud migration, AWS, digital transformation services, and mobile and Smart TV apps.</p>
          <p>Headquartered in the United States, with a significant presence in India, FusionShift Technologies combines global expertise with a local touch to create world-class platforms that inspire and empower users.</p>
          <h3>Our Mission</h3>
          <p>At Share Clix, our mission is simple:<br />
            To help you capture, cherish, and connect.
          </p>
          <p>We aim to provide an intuitive and secure platform where your memories can thrive, allowing you to share life's most meaningful moments with ease and creativity.</p>
          <h3>Why Choose Us?</h3>
          <p> <b>Seamless Experience:</b> Our user-friendly interface makes uploading, organizing, and sharing content effortless. </p>
          <p><b>Advanced Technology: </b>Built using cutting-edge solutions from FusionShift Technologies, Share Clix is powered by expertise in cloud migration, AWS, and mobile app development.</p>
          <p><b>Global Reliability: </b>With roots in the USA and India, we bring the best of global innovation to your fingertips.</p>
          <p><b>Privacy and Security:</b>Your memories are safe with us, thanks to robust privacy settings and secure data storage.</p>
          <p>The FusionShift Technologies Advantage</p>
          <p>For over 15 years, FusionShift Technologies has been at the forefront of digital innovation, serving clients worldwide across multiple domains:</p>
          <p>OTT Applications: Expertise in building top-tier streaming platforms. <br />
            Real Estate Apps: Innovative solutions for real estate management and transactions.<br />
            E-commerce Apps: Custom platforms tailored for seamless online shopping experiences.<br />
            Cloud Migration & AWS: Scalable, reliable cloud solutions for businesses of all sizes.<br />
            Digital Transformation: Helping organizations adapt and thrive in the digital age.<br />
            Mobile and Smart TV Apps: Creating apps that redefine user experiences on mobile and connected devices.

          </p>
          <p>Share Clix embodies the same commitment to innovation and user satisfaction, delivering a platform built with precision, passion, and purpose.</p>

          <h4>Join Us Today!</h4>
          <p>Start uploading, organizing, and sharing your stories on Share Clix—a platform built with love and expertise by FusionShift Technologies. Together, let’s turn your moments into memories and your memories into stories that last a lifetime.</p>
          <p>Have questions? Get in touch at [Insert Email Address]. Let’s make memories, together.</p>




        </div>

      </div>
      <Footer /> */}
    </div>

  );
};

export default About;
