import React from 'react';
// import ComingSoon from './ComingSoon';
import InsideHeader from './InsideHeader';
import Footer from './Footer';

const RefundPolicy = () => {
  return (
    // <ComingSoon image={image}/>
    <div className='inner_page'>
      <InsideHeader flag={true} />
      <div className='content_block'>
        <div className='content'>
          <h1>Refund Policy</h1>

          <h3>Effective Date: 01-01-2025</h3>
          <p>At shareclix.app, we are committed to providing a seamless and enjoyable experience for our users. If you encounter issues or are dissatisfied with our services, this refund policy outlines the conditions under which refunds may be issued.</p>
        <h4>1. Eligibility for Refunds</h4>
        <p> <b>Refunds may be provided under the following circumstances:</b> </p>
        <p> <b>Technical Issues:</b><br />
        If you are unable to access or use the services due to a technical issue caused by shareclix.app that cannot be resolved within a reasonable time frame.
        </p>
        <p> <b>Service Failure:</b><br />
        If features such as photo-sharing, album creation, or storage do not perform as described, and the issue cannot be resolved after reporting it to our support team.
        </p>
        <p><b>Duplicate Charges:</b><br />
        If you were mistakenly charged multiple times for the same subscription or service.
        </p>
        <h4>2. Non-Refundable Cases</h4>
        <p> <b>Refunds will not be issued in the following scenarios:</b> </p>
<p>If the service was used successfully, and no issues were reported during the usage period.
Dissatisfaction arising from personal preferences unrelated to service functionality (e.g., design, features).
Subscription payments for time already used, unless technical issues prevented full access.</p>
<h4>3. Refund Time Frame</h4>
<p> Refund requests must be submitted within 14 Days of the initial payment or subscription renewal date.
Requests made outside this period may not be eligible for a refund.</p>
<h4>4. How to Request a Refund</h4>
        <p> <b>To request a refund:</b> </p>
<p>Contact our customer support team at contact@shaeclix.app or through www.shareclix.app/contactus.</p>
<p> <b>Include the following details:</b> <br />
Your account email address.<br />
Proof of payment (e.g., receipt, transaction ID).<br />
A detailed explanation of the issue and why you are requesting a refund.<br />
Our team will review your request and respond within  5-7 business days.
 </p>
 <h4>5. Refund Method</h4>
 <p>Approved refunds will be issued to the original payment method used at the time of purchase.<br />
 It may take  5-10 business days for the refunded amount to reflect in your account, depending on your payment provider.
 </p>
 <h4>6. Cancellations and Modifications</h4>
 <p>Users may cancel subscriptions at any time through their account settings.<br />
 Canceling a subscription will stop future charges but will not result in a refund for the current billing period.
 </p>
 <h4>7. Contact Us</h4>
 <p>If you have questions about this refund policy or need assistance, feel free to contact us at:</p>
 <p>
 <b>Email:</b><a href="mail:contact@shareclix.app" target='_blank'> contact@shareclix.app</a><br />
 <b>Support Page: </b> <a href="www.shareclix.app/contactus">www.shareclix.app/contactus</a>
 </p>







       



        </div>

      </div>
      <Footer />
    </div>

  );
};

export default RefundPolicy;
