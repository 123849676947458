import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import InsideHeader from './InsideHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import { Uploader } from "../utils/upload"
import Loader from './Loader'
let { appname, lambda } = window.app;

const CreateAlbum = () => {
  const [image, setImg] = useState('');
  const [svgImage, setSvgImg] = useState('');
  const [Images, setImages] = useState([]);
  const [sourceImg, setSourceImg] = useState('');
  const [activeLoad, setActiveLoad] = useState(false);
  const [latestFiles, setLatestFiles] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [albumList, setAlbumList] = useState([]);
  const [cardWidths, setCardWidths] = useState({});
  const location = useLocation();
  const [albumName, setAlbumName] = useState(location?.state?.albumItem?.albumName || "");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [albumOptions, setAlbumOptions] = useState([]);
  const [selectedImages, setSelectedImages] = useState(location?.state?.selectedImages || []);
  const [albumDate, setAlbumDate] = useState(location?.state?.albumItem?.eventDate || null);
  const [albumItem, setAlbum] = useState(location?.state?.albumItem || null);
  const [errorMessage, setValidation] = useState(null);
  const [files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [tags, setTags] = useState('');
  const [albumId, setAlbumId] = useState(null);

  const [perf, setPerf] = useState(undefined)
  const [baseUrl, setBaseUrl] = useState("https://30rewddepd.execute-api.us-east-1.amazonaws.com/prod/")
  const [partsize, setPartsize] = useState(100)
  const [numuploads, setNumuploads] = useState(3)
  const [ta, setTa] = useState(undefined)
  const [closeClick, setCloseClick] = useState(false)
  const [pgvalue, setPgvalue] = useState(undefined)

  const handleSelect = (selectedList) => {
    setSelectedOptions(selectedList);
  };
  const cardWidth = $(".thumb-block").width() ? $(".thumb-block").width() : 160;

  const handleRemove = (selectedList) => {
    setSelectedOptions(selectedList);
  };

  useEffect(() => {
    if (location?.state?.albumItem) {
      localStorage.setItem('al', location?.state?.albumItem?.albumid)
      setAlbum(location?.state?.albumItem)
      setAlbumId(location?.state?.albumItem?.albumid)
      // localStorage.setItem('aln',location?.state?.albumItem?.albumName)
      // localStorage.setItem('ald',location?.state?.albumItem?.eventDate)
    }

  }, [location?.state?.albumItem]);


  // Calculate the widths of all thumb-img elements
  useEffect(() => {
    const widths = {};
    document.querySelectorAll(".thumb-img").forEach((img) => {
      const id = img.getAttribute("data-id");
      if (id) {
        widths[id] = img.clientWidth;
      }
    });
    setCardWidths(widths);
  }, [Images]); // Re-run when Images change

  const navigate = useNavigate();
  const getNotMatchedObjects = (arr1, arr2, key) => {
    const onlyInArr1 = arr1.filter(item1 => !arr2.some(item2 => item1[key] === item2[key]));
    const onlyInArr2 = arr2.filter(item2 => !arr1.some(item1 => item1[key] === item2[key]));

    return [...onlyInArr1, ...onlyInArr2];
  };
  // {albumList?.length > 0 &&
  //   albumList.map((album) => (
  //     <li key={album.albumid}>
  //       <a className="dropdown-item" onClick={() => handleAddToExistingAlbum(album)}>
  //         {album?.albumName}
  //       </a>
  //     </li>
  //   ))}
  const fetchAlbumInfo = async () => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const al = localStorage.getItem('al');
      setActiveLoad(true)
      const response = await axios.get(`${lambda}/albumInfo?appname=${appname}&clientid=${clientid}`);
      setAlbumList(response.data.result);

      let filteredAlbumId = al
      if (location?.state?.albumItem?.albumid) {
        filteredAlbumId = location?.state?.albumItem?.albumid
      }
      let options = response.data.result.map(album => ({
        name: album.albumName,
        id: album.albumid,
      }));
      options = options.filter(item => item?.id !== filteredAlbumId)
      console.log('filteredAlbumId ', filteredAlbumId)
      console.log('options ', options)
      setAlbumOptions(options)

      setActiveLoad(false)
    } catch (error) {
      setActiveLoad(false)
      console.error('Error fetching album info:', error);
    }
  };
  

  const fetchAlbumInfoId = async (albumId) => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      const response = await axios.get(`${lambda}/albumInfo?appname=${appname}&clientid=${clientid}&albumid=${albumId}`);
      setAlbum(response?.data?.result[0])
      // setAlbumName(response?.data?.result[0]?.albumName)
      // setAlbumDate(response?.data?.result[0]?.eventDate)
      setAlbumId(response?.data?.result[0]?.albumid)
    } catch (error) {

      console.error('Error fetching album info:', error);
    }
  };

  useEffect(() => {
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
        setSvgImg(window.site.common.resourcesCloudfront);
        setSourceImg(window.site.common.sourceCloudFront);
      } else {
        setTimeout(checkSiteObject, 50);
      }
    };
    checkSiteObject();
  }, []);

  const handleClose = () => {
    //if (!albumName) {
    //   navigate('/all-files')
    // } else {
    navigate('/albums')
    //}
    localStorage.removeItem('al')
    localStorage.removeItem('ald')
    localStorage.removeItem('aln')
  };
  useEffect(() => {
    fetchclientContentInfo();
    fetchAlbumInfo();
  }, []);

  useEffect(() => {
    if (albumName && albumDate) {
      setValidation(null)
    }
  }, [albumName, albumDate]);
  // const handleSelect = (selectedList) => {
  //   if (selectedList.length > 3) {
  //     setShowSearch(true);
  //   }
  // };
  const fetchclientContentInfo = async () => {
    try {
      setActiveLoad(true)
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      let url = `${lambda}/clientContentInfo?appname=${appname}&clientid=${clientid}`

      let storageAlbumId = localStorage.getItem('al')
      let albname = localStorage.getItem('aln')
      let albdate = localStorage.getItem('ald')
      if (albname) {
        setAlbumName(albname)
      }
      if (albdate) {
        setAlbumDate(albdate)
      }

      if (storageAlbumId) {
        url = url + `&albumid=${storageAlbumId}`
        setAlbumId(storageAlbumId)
        fetchAlbumInfoId(storageAlbumId)



      } else if (location?.state?.albumItem?.albumid) {
        url = url + `&albumid=${location?.state?.albumItem?.albumid}`
      }
      console.log('url', url)
      const response = await axios.get(url);

      // if (location?.state?.albumItem) {
      //   let notMatched = getNotMatchedObjects(response?.data?.result, location?.state?.albumItem?.contentids, 'contentid');

      //   setImages(notMatched)
      // } else {
      setImages(response.data.result);
      // }

      setActiveLoad(false)
    } catch (error) {
      console.error('Error fetching Images:', error);
    }
  };
  const handleDoneUpload = () => {

    if (albumName && albumDate) {
      setValidation(null)
      if (albumName?.length > 0) {
        handleAddToAlbum()
      }

      if (!location?.state?.albumItem?.albumid) {
        if (selectedOptions?.length > 0) {
          handleAddToExistingAlbum()
        }
      }
    } else {
      setTimeout(() => {
        // setValidation(null)
      }, 2000);
      setValidation('Please enter Event name and Event date')
      // toast.error('Please enter Event name and Event date');
    }


  }
  // const handleAddToExistingAlbum = async (albumItem) => {
  //   try {
  //     const albumid = albumItem?.albumid;
  //     const clientid = localStorage.getItem('clientid');
  const token = localStorage.getItem('token');
  //     setActiveLoad(true)
  //     const response = await axios.post(`${lambda}/addAlbum?appname=${appname}&clientid=${clientid}&albumid=${albumid}`, {
  //       contentid: selectedImages
  //     });
  //     console.log('Images added to album:', response.data);
  //     setActiveLoad(false)
  //     setSelectedImages([]);
  //     toast.success(`Images added to ${albumItem.albumName} successfully!`);
  //   } catch (error) {
  //     setActiveLoad(false)
  //     console.error('Error adding images to album:', error);
  //     toast.error('Failed to add images to album.');
  //   }
  // };
  const handleAddToExistingAlbum = async () => {
    setActiveLoad(true);

    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      // Loop through selectedOptions and make an API call for each
      for (const option of selectedOptions) {
        const response = await axios.post(
          `${lambda}/addAlbum?appname=${appname}&clientid=${clientid}&albumid=${option.id}`,
          {
            albumName: option?.name,
            contentids: selectedImages
          }
        );
        console.log(`Images added to album ${option.name}:`, response.data);
      }

      setActiveLoad(false);
      setSelectedImages([]);
      toast.success(`Images added to selected albums successfully!`);
      // navigate('/albums');
      localStorage.removeItem('al')
      localStorage.removeItem('ald')
      localStorage.removeItem('aln')
      if (albumId) {
        navigate('/viewalbum/' + albumId);

      } else {
        navigate('/albums');
      }

    } catch (error) {
      setActiveLoad(false);
      console.error('Error adding images to album:', error);
      toast.error('Failed to add images to album.');
    }
  };

  const handleAddToAlbum = async () => {
    if (!albumName.trim()) {
      toast.error('Please enter an album name.');
      return;
    }
    try {
      setActiveLoad(true)
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      let albumArray = albumId ? [albumId] : []

      if (selectedOptions && selectedOptions.length > 0) {
        selectedOptions.forEach(album => (
          albumArray.push(album.id)
        ));
      }
      console.log('albumArray ', albumArray)
      let thumbImage = selectedImages?.[0].filepath
      if(selectedImages?.[0].filetype == "video/mp4"){
        thumbImage = 'images/app/images/play-default.jpg'
      }
      let albumObj = {
        albumName: albumName,
        contentids: selectedImages,
        createdOn: new Date(),
        eventDate: albumDate,
        // thumbnail: thumbImage,
        // thumbnailId: selectedImages?.[0].contentid,
      }

      if (albumArray.length > 0) {
        albumObj['albumids'] = albumArray
      }else {
        albumObj["thumbnail"] = thumbImage
        albumObj["thumbnailId"] = selectedImages?.[0].contentid
      }
      console.log('albumObj ', albumObj)
      const response = await axios.post(
        `${lambda}/addAlbum?appname=${appname}&clientid=${clientid}${albumId ? `&albumid=${albumId}` : ''}`,
        albumObj
      );

      setActiveLoad(false)
      console.log('Images added to album:', response.data);
      setSelectedImages([]);
      toast.success(`Images added to ${albumName} successfully!`);
      localStorage.removeItem('al')
      localStorage.removeItem('ald')
      localStorage.removeItem('aln')
      if (albumId) {
        navigate('/viewalbum/' + albumId);
      } else {
        navigate('/albums');
      }
    } catch (error) {
      setActiveLoad(false)
      console.error('Error adding images to album:', error);
      toast.error('Failed to add images to album.');
    }
  };

  // const handleSelectImage = (id) => {
  //     setSelectedImages((prev) => {
  //         if (prev.includes(id)) {
  //             return prev.filter((item) => item !== id);
  //         } else {
  //             return [...prev, id];
  //         }
  //     });
  // };
  console.log('selectedImages', selectedImages)

  const handleChange = (e) => {
    if (e.target.name == "albumname") {
      setAlbumName(e.target.value)
      localStorage.setItem('aln', e.target.value)
    } else if (e.target.name == "date") {
      setAlbumDate(e.target.value)
      localStorage.setItem('ald', e.target.value)
    }

  }

  const handleSelectImage = (selectedItem) => {
    setSelectedImages((prev) => {
      const isSelected = prev.some(image => image.contentid === selectedItem.contentid);

      if (isSelected) {
        // Remove if already selected
        return prev.filter(image => image.contentid !== selectedItem.contentid);
      } else {
        // Add new selected object
        return [...prev, {
          contentid: selectedItem.contentid,
          filepath: selectedItem.filepath,
          filename: selectedItem.filename,
          filetype: selectedItem.filetype,
          createdOn: selectedItem.createdOn

        }];
      }
    });
  };
  useEffect(() => {
    if (location.state) {
      setTotalSize(location.state.totalSize);
      setFiles(location.state.files);
      setTags(location.state.tags);
    }

  }, [location.state]);

  useEffect(() => {
    if (files?.length > 0 && totalSize) {
      handleUploadfunction(files, totalSize)
    }

  }, [totalSize, files]);

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = () => {
        reject(new Error('Error loading video metadata'));
      };

      video.src = URL.createObjectURL(file);
    });
  };
  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const createProgressToast = (fileName) => {
    let progressToastId = toast(`Uploading ${fileName}...`, {
      progress: 0,
      closeOnClick: false,
      autoClose: false,

    });

    return progressToastId;
  };

  // Function to update progress toast
  const updateProgressToast = (toastId, progress) => {
    toast.update(toastId, {
      progress: progress / 100,
    });
  };

  // Function to close and show success or error message
  const finalizeToast = (toastId, fileName, success = true) => {
    toast.update(toastId, {
      render: success ? `${fileName} uploaded successfully!` : `${fileName} failed to upload.`,
      type: success ? 'success' : 'error',  // Using 'success' and 'error' as strings
      autoClose: 2000,
      closeOnClick: true,
    });

  };
  const handleUploadfunction = async (files, totalSize) => {

    // setLatestFiles(files)
    console.log('windo.site.state', window?.site)


    if (!files || files.length === 0) {
      Swal.fire({
        title: 'No Files Selected',
        text: 'Please select a file to upload.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
      return;
    }

    const uploadPromises = Array.from(files).map(async (file) => {
      const timestamp = Date.now();
      const fileExtension = file.name.split('.').pop();
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const ObjectID = require("bson-objectid");
      let storageAlbumId = localStorage.getItem('al')
      const uniqueId = new ObjectID().toString();
      let uploadFilePath;
      let bucket;

      // Check the file type
      if (file.type.startsWith('video/')) {
        // File is a video
        bucket = window.site && window.site.common && window.site.common.sourceBucket; // Use source bucket for videos
        // videoUploadFunction(uploadFilePath,bucket)
        // const timestamp = Date.now();
        // const fileExtension = file?.name?.split('.').pop();
        // const clientid = localStorage.getItem('clientid');

        // const ObjectID = require("bson-objectid");
        // const uniqueId = new ObjectID().toString();
        const uploadFilePath = `${clientid}/files/${uniqueId}/`;
        const fileNameUnique = `${uniqueId}_video_${timestamp}.${fileExtension}`;

        // Track upload progress
        const uploadPromise = getVideoDuration(file).then(async (duration) => {
          const formattedDuration = formatDuration(duration);

          const uploaderOptions = {
            file: file,
            baseURL: baseUrl,
            chunkSize: partsize,
            threadsQuantity: numuploads,
            useTransferAcceleration: ta,
            bucket: uploadFilePath,
            fileNameUnique: fileNameUnique,
            sourceBucket: bucket
          };

          let percentage = undefined;
          setPgvalue(0);
          setPerf("-");
          const uploader = new Uploader(uploaderOptions);
          const tBegin = performance.now();
          console.log('tBegin', tBegin);

          const progressToastId = createProgressToast(file.name);

          uploader
            .onProgress(({ percentage: newPercentage }) => {
              // Avoid logging the same percentage twice
              if (newPercentage !== percentage) {
                percentage = newPercentage;
                setPgvalue(percentage);
                updateProgressToast(progressToastId, percentage);

                if (percentage === 100) {
                  setPerf((performance.now() - tBegin) / 1000);


                  // const pushtoElementalPayload = {
                  //   "params": {
                  //     "Destination": window?.site?.common?.proxiesBucket + "/",
                  //     "NameModifier": fileNameUnique,
                  //     "appName": appname,
                  //     "clientFileId": uniqueId,
                  //     "imagesbucket": window?.site?.common?.resourceBucket,
                  //     "myBucket": window?.site?.common?.sourceBucket,
                  //     "path": uploadFilePath + fileNameUnique,
                  //     "proxiesBucket": window?.site?.common?.proxiesBucket
                  //   },
                  //   "presets": [{
                  //     "profile": "HD",
                  //     "resolution": "720p",
                  //     "Aspect ratio": "16:9",
                  //     "Preset": "720P-6.5Mbps",
                  //     "NameModifier": "_720p",
                  //     "duration": "In Min",
                  //     "dimensions": "1280×720",
                  //     "name": "High Definition",
                  //     "price": [
                  //       {
                  //         "from": 0,
                  //         "to": 0,
                  //         "cost": 4,
                  //         "currency": "$"
                  //       }
                  //     ]
                  //   }]
                  // }
                  //setTimeout(function () {
                  // axios.post('https://1mgtsjq0kj.execute-api.us-east-1.amazonaws.com/latest/pushtoElemental?appname=${appname}&clientid=' + clientid,
                  //   pushtoElementalPayload
                  //   // { grandTotal }
                  // ).then(res => {
                  // console.log('Pushtoelementanal', res)

                  // })


                  const newFile = {
                    filename: fileNameUnique,
                    filepath: uploadFilePath,
                    fileid: uniqueId,
                    filetype: file.type,
                    filesize: file.size,
                    fileformat: fileExtension,
                    fileDuration: formattedDuration,
                    tags
                  };

                  setLatestFiles(prevLatestFiles => [...prevLatestFiles, newFile]);

                  return axios.post(
                    `${lambda}/clientContent?appname=${appname}&clientid=${clientid}`,
                    newFile
                  ).then(clientContentResponse => {
                    if (clientContentResponse.status === 200) {

                      let url = `${lambda}/clientContentInfo?appname=${appname}&clientid=${clientid}`

                      if (storageAlbumId) {
                        url = url + `&albumid=${storageAlbumId}`
                      }

                      return axios.get(url).then(clientContentInfoResponse => {
                        if (clientContentInfoResponse.status === 200) {
                          const result = clientContentInfoResponse.data.result;
                          setImages(result);
                          navigate(location.pathname, { replace: true, state: null });
                          finalizeToast(progressToastId, file.name, true);
                          return result;
                        } else {
                          finalizeToast(progressToastId, file.name, false);
                          throw new Error('Failed to get user file info');
                        }
                      });
                    } else {
                      finalizeToast(progressToastId, file.name, false);
                      throw new Error('Failed to add user file');
                    }
                  });
                  // }, 5000)
                }
              }
            })
            .onError((error) => {
              console.error(error);
              finalizeToast(progressToastId, file.name, false);
            });

          uploader.start();
        });

        return uploadPromise;
      } else if (file.type.startsWith('image/')) {
        // File is an image
        uploadFilePath = `images/client/${clientid}/files/${file.name.split('.')[0]}_image_${timestamp}.${fileExtension}`;
        bucket = window.site.common.resourceBucket; // Use resource bucket for images

        console.log('Upload File Path:', uploadFilePath);

        const data = {
          source_bucket: bucket, // Use the correct bucket based on file type
          sourcepath: uploadFilePath
        };

        console.log('Upload Data:', data);

        const newFile = {
          filename: file.name.split('.')[0],
          filepath: uploadFilePath,
          fileid: uniqueId,
          filetype: file.type,
          filesize: file.size,
          fileformat: fileExtension,
        };

        setLatestFiles(prevLatestFiles => [...prevLatestFiles, newFile]);
        setActiveLoad(true)

        const uploadPromise = axios.post(
          `${lambda}/uploadFiles?appname=${appname}`,
          data,
          { headers: { 'Content-Type': 'application/json' } }
        ).then(uploadUrlResponse => {
          setActiveLoad(false)
          if (uploadUrlResponse.data && uploadUrlResponse.data.result) {
            const uploadUrl = uploadUrlResponse.data.result;

            return axios.put(uploadUrl, file, {
              headers: {
                'Content-Type': file.type,
                'Cache-Control': 'no-cache'
              }
            }).then(uploadResponse => {
              setActiveLoad(true)
              if (uploadResponse.status === 200) {
                return axios.post(
                  `${lambda}/clientContent?appname=${appname}&clientid=${clientid}`,
                  newFile
                ).then(clientContentResponse => {
                  setActiveLoad(false)
                  if (clientContentResponse.status === 200) {
                    setActiveLoad(true)
                    let url = `${lambda}/clientContentInfo?appname=${appname}&clientid=${clientid}`

                    if (storageAlbumId) {
                      url = url + `&albumid=${storageAlbumId}`
                    }

                    return axios.get(url).then(clientContentInfoResponse => {
                      setActiveLoad(false)
                      if (clientContentInfoResponse.status === 200) {
                        const result = clientContentInfoResponse.data.result;
                        setImages(result);
                        navigate(location.pathname, { replace: true, state: null });
                        return result;
                      } else {
                        throw new Error('Failed to get user file info');
                      }
                    });
                  } else {
                    throw new Error('Failed to add user file');
                  }
                });
              } else {
                throw new Error('Failed to upload file to S3');
              }
            });
          } else {
            throw new Error('Failed to get upload URL');
          }
        });

        return toast.promise(
          uploadPromise,
          {
            pending: `Uploading ${file.name}...`,
            success: `${file.name} uploaded successfully!`,
            error: `${file.name} failed to upload.`,
          }
        );
      } else {
        // File type is neither image nor video
        toast.error('Unsupported file type');
        return;
      }






    });

    try {
      const results = await Promise.all(uploadPromises);

    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };



  const handleSelectAll = (e) => {


    if (e.target.checked == true) {
      let imagesSelected = [...selectedImages]
      //let finalSelected = []
      // let finalSelected = imagesSelected.filter(item => item.selectDate == data)
      let notMatched = getNotMatchedObjects(Images, imagesSelected, 'contentid');

      if (notMatched && notMatched?.length == 0) {
        notMatched = Images
      }
      notMatched?.forEach((item) => {
        document.getElementById('checkbox-' + item.contentid).click();
      });
    } else {
      setSelectedImages([])
    }

  }

  // console.log("locationss", selectedImages)

  return (
    <div>
      <div id="page-top">
        <div className="fixed-top shadow-sm fullpop-header">
          <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-between">
              <h1 className="md-heading lite-text">Albums</h1>
              <button className="gen-close" onClick={handleClose}>
                {/* <span className="small">Close</span> */}
                <span className="material-symbols-outlined icon">close_small</span>
              </button>
            </div>
          </div>
        </div>
        <div className="album-body">
          {activeLoad && <Loader />}
          <div className='album-actions'>
            <div className='album-name'>
              <h6>Event</h6>

              <div className='row'>

                <div className="col-md-6">
                  <div className="form-group">

                    <label>Event Name *</label>
                    <input type="name" name="albumname" onChange={(e) => handleChange(e)} value={albumName} className="form-control" placeholder="Enter Name" />
                  </div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Event Date *</label>
                    <input type="date" name="date" onChange={(e) => handleChange(e)} value={albumDate} className="form-control" placeholder="Select Date" />
                  </div></div>

              </div>
              <p className='text-danger'>{errorMessage}</p>
            </div>
            {albumName && albumId &&
              <div className='existing-albums'>
                <h6>Select Events</h6>

                <div className="form-group">
                  <label>Add Images/Videos into the existing Albums ( Optional )</label>
                  <Multiselect
                    options={albumOptions}
                    selectedValues={selectedOptions}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                    customCloseIcon={<span className="material-symbols-outlined">
                      close_small
                    </span>}
                    className="form-control form-select"
                    showCheckbox
                    displayValue="name" // Display the `name` property of each option
                  />
                </div>
              </div>}
          </div>



          <div className="content-block">


            <div className="d-flex align-items-center justify-content-between mb-3 album-action-top">


            <div className="d-flex align-items-center">
              <h3 className="sm-heading bold-txt lite-text mb-0 mt-0">Select below Images/Videos to add into Album</h3>

              <div className='selectall'>
                <input type="checkbox" id="selectall" onClick={(e) => handleSelectAll(e)} />
                <label>Select All</label>
              </div>
              </div>
              <button
                className="btn gradietn_btn rounded-pill"
                // onClick={() => document.getElementById('file-upload').click()}
                onClick={() => navigate('/fileupload', { state: { returnRoute: "event", albumItem: albumItem, selectedImages } })}

              >
                Upload from Computer<span className="material-icons ms-2">upload</span>
              </button>




              {/* <input
                type="file"
                id="file-upload"
                accept="image/*, video/*"
                className="file-input"
                multiple
                onChange={(e) => handleUploadfunction(e, "none")}
                style={{ display: 'none' }}
              /> */}

            </div>
            {latestFiles?.length > 0 && <>
              <div className="fusion-row">
            
                {Images.filter(img => latestFiles.some(latest => latest.fileid === img.fileid))?.map((item) => (
                  <div className="column10 g-3" key={item.contentid}>
                    <div className={`thumb-block ${selectedImages.some(image => image.contentid === item.contentid) ? 'video-block selected' : ''}`}>
                      <div className="dummy-thumb"></div>
                      <div className="check-block">
                        <label className="check-container">
                          <input
                            type="checkbox"
                            id={`checkbox-${item.contentid}`}
                            onClick={() => handleSelectImage(item)}
                            checked={selectedImages.some(image => image.contentid === item.contentid) ? true : false}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {item?.filetype?.startsWith('video/') ? (
                        <img
                          src={`${image}images/app/images/play-default.jpg`}
                          className="thumb-img"
                          alt="thumbnail"
                          data-id={item.contentid}
                        />
                      ) : (
                        <img
                          src={`${image}${item.filepath}?auto=compress,format&width=${cardWidth}`}
                          className="thumb-img"
                          alt="thumbnail"
                          data-id={item.contentid}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
            </>

            }
            <div className="fusion-row">
              {Images
                .filter(img => !latestFiles.some(latest => latest.fileid === img.fileid))
                ?.map((item) => (
                  <div className="column10 g-3" key={item.contentid}>
                    <div className={`thumb-block ${selectedImages.some(image => image.contentid === item.contentid) ? 'video-block selected' : ''}`}>
                      <div className="dummy-thumb"></div>
                      <div className="check-block">
                        <label className="check-container">
                          <input
                            type="checkbox"
                            id={`checkbox-${item.contentid}`}
                            onClick={() => handleSelectImage(item)}
                            checked={selectedImages.some(image => image.contentid === item.contentid) ? true : false}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {item?.filetype?.startsWith('video/') ? (
                        <img
                          src={`${image}images/app/images/play-default.jpg`}
                          className="thumb-img"
                          alt="thumbnail"
                          data-id={item.contentid}
                        />
                      ) : (
                        <img
                          src={`${image}${item.filepath}?auto=compress,format&width=${cardWidths[item.contentid] || 0}`}
                          className="thumb-img"
                          alt="thumbnail"
                          data-id={item.contentid}
                        />
                      )}
                    </div>
                  </div>
                ))}

            </div>
            {/* {selectedImages?.length > 0 &&  */}
            <div className="content-footer show">

              <button className="btn gradietn_btn rounded-pill px-3 py-2 mb-lg-0" disabled={selectedImages?.length <= 0} onClick={handleDoneUpload}>
                Done<span className="material-icons ms-2">done</span>
              </button>

              <div className='errormessage'><p className='text-danger'>{errorMessage}</p></div>
            </div>
            {/* } */}
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default CreateAlbum;