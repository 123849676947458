import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Loader from './Loader'
import ReactPlayer from 'react-player'
import InsideHeader from './InsideHeader';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Draggable } from "react-drag-reorder";
let { appname, lambda } = window.app;

const ViewAlbum = () => {
  const [Images, setImages] = useState();
  const [selectedImages, setSelectedImages] = useState([]); // Store selected images
  const [ToggleSwitch, setToggleSwitch] = useState(false);
  const [albumItem, setAlbumItem] = useState({});
  const [albumContent, setAlbumContent] = useState([]);
  const [albumData, setAlbumData] = useState([]);
  const [image, setImg] = useState('');
  const [sourceImg, setSourceImg] = useState('');
  const [showDocAlert, setShowDocAlert] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();  // Extracting the id from the URL
  const [cardWidths, setCardWidths] = useState({});
  const [videoPlayer, setVideoPlayer] = useState(false);
  const [playerPath, setplayerPath] = useState(null);
  const [largeImage, setLargeImage] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [sortData, setSortData] = useState({});
  const [activeLoad, setActiveLoad] = useState(false);
  const [filterType, setFilterType] = useState("image");
  const [cardWidth1, setCardWidth1] = useState(window.innerHeight * 0.8);
  const [proxiesCloudFront, setProxiesCloudFront] = useState('');
  const [tabView, setTabView] = useState(true);
  const [thumbnail, setThumbnail] = useState(false);

  const imageRef = useRef(null); // Ref for the image
  const divRef = useRef(null); // Ref for the div
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageWidth, setImageWidth] = useState(200)

  useEffect(() => {
    if (imageRef.current && divRef.current && imageLoaded) {
      const imgWidth = imageRef.current.offsetWidth; // Get the image width
      // console.log('imgWidth', imgWidth)
      setImageWidth(imgWidth)
      // divRef.current.style.width = `${imageWidth}px`; // Set the div width
    }
  }, [imageLoaded]); // Run effect when the image is loaded

  // useEffect(() => {

  //      console.log('imageWidthsss', imageWidth)
  //      setCardWidths(imageWidth);
  // }, [imageWidth]);


  // Calculate the widths of all thumb-img elements
  useEffect(() => {
    // const widths = {};
    // let imgwidth = document.querySelectorAll("#data-id");
    // console.log('imgss', imgwidth)
    // var element = document.getElementById("data-id");
    // var width = element?.clientWidth;
    // console.log("Width (content only):", width);

    // var computedWidth = window.getComputedStyle(element).width;
    // console.log("Computed Width:", computedWidth);

    // document.querySelectorAll(".thumb-img").forEach((img) => {
    //   const id = img.getAttribute("data-id");
    //   if (id) {
    //     widths[id] = img.clientWidth;
    //   }
    // });
    // setCardWidths(widths);

    setThumbnail(albumItem?.thumbnail)
  }, [albumItem]); // Re-run when albumItem changes

  useEffect(() => {
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
        setSourceImg(window.site.common.sourceCloudFront);
        setProxiesCloudFront(window.site.common.proxiesCloudFront);

        fetchAlbumInfo(filterType);
      } else {
        setTimeout(checkSiteObject, 50);
      }
    };
    checkSiteObject();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      const imageHeight = window.innerHeight * 1; // 80% of 80% (20% reduction)
      setCardWidth1(imageHeight); // Update width based on window size
    };
    window.addEventListener('resize', handleResize);

    // Set initial width on mount
    handleResize();
    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const fetchclientContentInfo = async () => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/clientContentInfo?appname=${appname}&clientid=${clientid}`);
      setImages(response.data.result);
    } catch (error) {
      console.error('Error fetching Images:', error);
    }
  };

  const fetchAlbumInfo = async (filteredValue) => {
    setActiveLoad(true)
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/albumInfo?appname=${appname}&clientid=${clientid}&albumid=${id}&filetype=${filteredValue}`);

      setAlbumItem(response.data.result?.[0]);
      setAlbumData(response.data.result?.[0]);
      setAlbumContent(response.data.result?.[0]?.contentids)
      setActiveLoad(false)
    } catch (error) {
      setActiveLoad(false)
      console.error('Error fetching album info:', error);
    }
  };

  useEffect(() => {
    // fetchAlbumInfo(filterType);
    // handleFilterContent(filterType);
    // fetchclientContentInfo();
  }, []);

  useEffect(() => {
    if (selectedImages.length <= 0) {
      setToggleSwitch(false);
    }
  }, [selectedImages]);

  useEffect(() => {
    if (albumItem && albumItem?.contentids && albumItem?.contentids?.length > 0) {
      // let dateFiltered = groupBy(albumItem?.contentids, 'createdOn')
      // setSortData(dateFiltered)

    }

  }, [albumItem]);

  // const handleSelectImage = (id) => {
  //   setSelectedImages((prev) => {
  //     if (prev.includes(id)) {
  //       return prev.filter((item) => item !== id);
  //     } else {
  //       return [...prev, id];
  //     }
  //   });
  // };
  const handlePlayVideo = (item) => {

    setVideoPlayer(true)
    let filepath = sourceImg + "/" + item.filepath + item.filename

    if (item?.video?.m3u8?.proxyUrl) {
      filepath = proxiesCloudFront + item?.video?.m3u8?.proxyUrl
    }
    // console.log("filepathalbum",filepath)
    setplayerPath(filepath)
  };

  const handleImage = (item) => {
    setImagePopup(true)
    setLargeImage(item)
  };

  const handleSelectImage = (selectedItem) => {
    setSelectedImages((prev) => {
      const isSelected = prev.some(image => image.contentid === selectedItem.contentid);

      if (isSelected) {
        // Remove if already selected
        return prev.filter(image => image.contentid !== selectedItem.contentid);
      } else {
        // Add new selected object
        return [...prev, {
          contentid: selectedItem.contentid,
          filepath: selectedItem.filepath,
          filetype: selectedItem.filetype,
          createdOn: selectedItem.createdOn
        }];
      }
    });
  };

  const handleDeleteImages = async () => {
    try {
      console.log('Deleting images:', selectedImages);
      setImages((prevImages) => prevImages.filter((image) => !selectedImages.includes(image.contentid)));
      setSelectedImages([]);
    } catch (error) {
      console.error('Error deleting images:', error);
    }
  };

  const handleAddToAlbum = async (albumName) => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.post(`${lambda}/addAlbum?appname=${appname}&clientid=${clientid}`, {
        albumName: albumName,
        images: selectedImages
      });
      console.log('Images added to album:', response.data);
      setSelectedImages([]);
      toast.success(`Images added to ${albumName} successfully!`);
    } catch (error) {
      console.error('Error adding images to album:', error);
      toast.error('Failed to add images to album.');
    }
  };

  const handleAddToExistingAlbum = async (albumItem) => {
    try {
      const albumid = albumItem?.albumid;
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.post(`${lambda}/addAlbum?appname=${appname}&clientid=${clientid}&albumid=${albumid}`, {
        contentid: selectedImages
      });
      console.log('Images added to album:', response.data);
      setSelectedImages([]);
      toast.success(`Images added to ${albumItem.albumName} successfully!`);
    } catch (error) {
      console.error('Error adding images to album:', error);
      toast.error('Failed to add images to album.');
    }
  };
  const handleClose = () => {
    navigate('/albums');
  };

  const handleDeletePopup = (e) => {
    setShowDocAlert(true)
  };

  const weekOfMonth = (m) => {
    const firstWeekDay = moment(m).startOf('week').format("DD MMM YYYY");
    const lastWeekDay = moment(m).endOf('week').format("DD MMM YYYY");
    //  console.log("today ", today.toString())
    //  console.log("firstWeekDay ", firstWeekDay.toString())
    //  console.log("firstWeekDay ", lastWeekDay.toString())
    return firstWeekDay.toString() + " to " + lastWeekDay.toString()
    // return moment(m).week() - moment(m).startOf('month').week() + 1;
  }

  const groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      var key = new Date(obj[property]).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, ' ');
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  const handleSelectAll = (data) => {
    sortData[data].forEach((item) => {
      document.getElementById(item.contentid).click();
    });
  }

  const handleDeleteAlbumContent = async (item) => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const payload = {
        contentid: selectedImages.map(image => image.contentid)
      };
      const response = await axios.post(`${lambda}/deleteFiles?appname=${appname}&clientid=${clientid}&albumid=${id}&from=album`, {
        contentid: selectedImages.map(image => image.contentid)
      });

      if (response.status === 200) {
        setShowDocAlert(false)
        setSelectedImages([]);
        setFilterType("all")
        toast.success('Removed item from album successfully!');
        // fetchAlbumInfo();
        handleFilterContent(filterType);
        // Optionally, remove the album from the UI or refetch the album list here.
      } else {
        toast.error('Failed to delete the album. Please try again.');
      }

    } catch (error) {
      console.error('Error deleting album:', error);
      toast.error('An error occurred while deleting the album.');
    }
  };

  const handleSortGroup = (e) => {
    // console.log(e.target.value)

    if (e.target.value == "Week" && albumItem && albumItem?.contentids && albumItem?.contentids?.length > 0) {
      //  if (albumItem && albumItem?.contentids && albumItem?.contentids?.length > 0)

      const groupofweek = albumItem?.contentids.reduce((acc, item) => {

        let date = item.createdOn
        let weekOfCurrentMonth = weekOfMonth(date);

        const yearWeekofMonth = `${weekOfCurrentMonth}`;
        // console.log("weekOfCurrentMonth ", weekOfCurrentMonth)
        // add this key as a property to the result object
        if (!acc[yearWeekofMonth]) {
          acc[yearWeekofMonth] = [];
        }
        // push the current date that belongs to the year-week calculated befor
        acc[yearWeekofMonth].push(item);

        return acc;

      }, {});
      setSortData(groupofweek)
    } else if (e.target.value == "Month" && albumItem && albumItem?.contentids && albumItem?.contentids?.length > 0) {
      const groupsmonth = albumItem?.contentids.reduce((acc, item) => {
        let date = item.createdOn
        // create a composed key: 'year-week' 
        const yearWeek = `${moment(date).format("MMM")} ${moment(date).year()} `;
        // console.log("yearWeek ", yearWeek)
        // add this key as a property to the result object
        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }

        // push the current date that belongs to the year-week calculated befor
        acc[yearWeek].push(item);

        return acc;

      }, {});
      setSortData(groupsmonth)
    } else if (albumItem && albumItem?.contentids && albumItem?.contentids?.length > 0) {
      // setSortData({})
      let dateFiltered = groupBy(albumItem?.contentids, 'createdOn')
      setSortData(dateFiltered)
    }


  };

  const handleFilterType = (e) => {
    let filteredValue = e.target.value
    setFilterType(e.target.value)
    let contentFilter = [...albumContent]
    let albumItemData = { ...albumItem }
    let contentData = albumContent
    if (filteredValue != "all") {
      contentData = contentFilter.filter((item) => item?.filetype?.startsWith(filteredValue))
    }
    albumItemData["contentids"] = contentData
    setAlbumItem(albumItemData)

  }


  const handleFilterContent = async (value) => {
    let filteredValue = value
    setFilterType(value)
    setActiveLoad(true)
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      const response = await axios.get(`${lambda}/albumInfo?appname=${appname}&clientid=${clientid}&albumid=${id}&filetype=${filteredValue}`);

      if (response.status === 200) {
        setActiveLoad(false)
        // toast.success('Removed item from album successfully!');
        setAlbumItem(response?.data?.result[0])
        // Optionally, remove the album from the UI or refetch the album list here.
      } else {
        setActiveLoad(false)
        toast.error('Failed to filter the album. Please try again.');
      }

    } catch (error) {
      console.error('Error deleting album:', error);
      toast.error('An error occurred while filter the album.');
    }
  };

  const handleImageEdit = (contentId, filePath, item) => {
    navigate('/edit/' + contentId, { state: { filePath, item } })
  };



  const handleSetThumb = async (item, albumItem) => {

    try {
      // setActiveLoad(true)
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${lambda}/addAlbum?appname=${appname}&clientid=${clientid}&albumid=${albumItem?.albumid}`,
        {
          thumbnail: item.filepath,
          thumbnailId: item.contentid,
          albumids: [albumItem?.albumid],
          albumName: albumItem?.albumName,
          eventDate: albumItem?.eventDate,
        }
      );
      // setActiveLoad(false)
      // console.log('Images added to album:', item.filepath , albumItem);
      // setActiveLoad(false)
      setThumbnail(item.filepath)
      toast.success(`Thumbnail set successfully!`);
      // navigate(-1);
    } catch (error) {
      setActiveLoad(false)
      console.error('Error adding images to album:', error);
      toast.error('Failed to update Thumbnail.');
    }
  };

  const handleReorder = (currentPosition, newPosition) => {
    const updatedItems = Array.from(albumContent);

    // Move the item to the new position
    const [movedItem] = updatedItems.splice(currentPosition, 1);
    updatedItems.splice(newPosition, 0, movedItem);

    // Update the order key for each item based on its new index
    const reorderedItems = updatedItems.map((item, index) => ({
      ...item,
      order: index + 1
    }));

    setAlbumContent(reorderedItems);
    setTimeout(() => {
      handleEditAlbum(reorderedItems)
    }, 2000);

  };

  const handleEditAlbum = async (reorderedItems) => {

    try {
      // setActiveLoad(true)
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${lambda}/orderAlbum?appname=${appname}&clientid=${clientid}&albumid=${albumItem?.albumid}`,
        {
          // albumName: albumItem?.albumName,
          // eventDate: albumItem?.eventDate,
          // albumids: [albumItem?.albumid],
          contentids: reorderedItems
        }
      );

      setActiveLoad(false)
      console.log('Images added to album:', response.data);

      fetchAlbumInfo(filterType);
      toast.success(`Order Updated successfully!`);
      // navigate(-1);
    } catch (error) {
      setActiveLoad(false)
      console.error('Error adding images to album:', error);
      toast.error('Failed to update album order');
    }
  };

  console.log('imageWidth,', imageWidth)
  return (
    <div id="page-top">
      {/* <Navbar /> */}
      <InsideHeader flag={true} />
      <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
        <div className="container-fluid">
          <a className="navbar-brand fw-bold" onClick={() => [navigate("/")]}>
            <img src={`${image}images/app/images/logo-dark1.png`} className="brand-img lightlogo" alt="Logo" />
            <img src={`${image}images/app/images/logo-white1.png`} className="brand-img darklogo" alt="Logo" />

          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i className="bi-list"></i>
          </button>
          <button className="gen-close" onClick={handleClose}>

            <span className="material-symbols-outlined icon">close_small</span>
          </button>
        </div>
      </nav>
      <div className="inner-body">
        <div className="inner-wrapper">

          <Sidebar />
          <div className="right-section">
            <div className="page-title">
              <div className='d-flex align-items-center'>
                <h1 className="sm-heading lite-text">{albumItem?.albumName}</h1>
                <div className='mx-5 picsviews-actions images-videos-button'>
                  <button type="button" className={`${tabView ? 'active' : ""}`} onClick={(e) => { setTabView(true); handleFilterContent('image') }}>
                    <span className='material-symbols-outlined me-1'>photo_library</span>Images</button>
                  <button type="button" className={`${!tabView ? 'active' : ""}`} onClick={(e) => { setTabView(false); handleFilterContent('video') }}> <span className='material-symbols-outlined me-1'>video_library</span>Videos</button></div>
              </div>
              <div className="d-flex align-items-center">

                <button className="btn_outline gradietn_btn mr-10" onClick={() => navigate('/event', { state: { albumItem: albumData } })}>
                  Upload
                </button>

                {/* <label className="form-label">Filter By</label>
                <select className="form-select" placeholder="Select" name="sortby" onChange={(e) => handleFilterContent(e.target.value)} value={filterType} >
                  <option value="all">All</option>
                  <option value="image">Images</option>
                  <option value="video">Videos</option>
                </select> */}
              </div>


            </div>
            <div className="content-block">
              {/* <h3 className="sm-heading bold-txt lite-text">
                {new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, ' ')}
              </h3> */}




              {activeLoad && <Loader />}

              {!activeLoad && albumItem?.contentids?.length > 0 ?
                <div className='fusion-row'>



                  <Draggable
                    onPosChange={handleReorder}
                    className="drag-container g-3 column8"
                    itemClass="drag-item"
                  >
                    {albumItem && albumItem?.contentids && albumItem?.contentids?.length > 0 && albumItem?.contentids?.map((item) => (

                      <div className="column8 g-3" key={item.contentid} ref={divRef}>

                        <div className={`thumb-block ${albumItem?.thumbnailId == item?.contentid ? 'thumb_selected' : ''} ${selectedImages.some(image => image.contentid === item.contentid) ? 'selected' : ''}`}>
                          <div className="dummy-thumb" onClick={() => item?.filetype?.startsWith('video/') ? handlePlayVideo(sourceImg + "/" + item.filepath) : handleImage(`${image}${item.filepath}`)}></div>
                          <div className="check-block">
                            <label className="check-container">
                              <input
                                type="checkbox"
                                id={`${item.contentid}`}
                                onClick={() => handleSelectImage(item)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          {item?.filetype?.startsWith('video/') ? (<>
                            <div className="thumbactions" onClick={() => handlePlayVideo(item)}>
                              <span className="material-symbols-outlined"> play_arrow</span>
                            </div>
                            <img
                              src={`${image}images/app/images/play-default.jpg`}
                              className="thumb-img"
                              alt="thumbnail"
                              data-id={item.contentid}

                            /></>

                          ) : (
                            <><div className="thumbactions" >
                              <span className="material-symbols-outlined" title="View" onClick={() => handleImage(`${image}${item.filepath}`)}>
                                expand_content
                              </span>
                              {/* <span className="material-symbols-outlined" onClick={(e) => handleImageEdit(item.contentid, image + item.filepath, item)}>edit</span> */}

                              <span className="material-symbols-outlined" title="Set Thumbnail" onClick={(e) => handleSetThumb(item, albumItem)}>gallery_thumbnail</span>

                            </div>
                              <img
                                src={`${image}${item.filepath}?auto=compress,format&width=${imageWidth}`}
                                className="thumb-img"
                                alt="thumbnail"
                                data-id={item.contentid}
                                ref={imageRef}
                                onLoad={() => setImageLoaded(true)}
                              />

                            </>
                          )}
                        </div>


                      </div>
                    ))}
                  </Draggable>
                </div>
                : !activeLoad &&
                <div className="fusion-row">
                  <div className="content-block blankpage allfiles-blankpage">
                    <div className="text-center transform-center blank-image-fit">
                      <img src={`${image}images/app/images/default-img.png`} alt="albumContent" />
                      {filterType == "all" ? <p>Don't let moments slip away.<br />Start building your album</p> :
                        filterType == "video" ?  <h3 className="blankpage-title">NO VIDEOS FOUND</h3> :
                          filterType == "image" &&  <h3 className="blankpage-title">NO IMAGES FOUND</h3>}
                    </div>
                  </div>
                </div>

              }

            </div>


            <Modal className="modal fade video-popup" show={videoPlayer}>
              <div className="modal-body">
                <button className="close-btn"><span className="material-icons" onClick={e => setVideoPlayer(false)}>close</span></button>
                <div className='player-wrapper'> <ReactPlayer url={playerPath} playing controls />
                </div> </div>
            </Modal>

            <Modal className="modal fade image-popup" show={imagePopup}>
              <div className="modal-body">
                <button className="close-btn"><span className="material-icons" onClick={e => setImagePopup(false)}>close</span></button>
                <img src={`${largeImage}?auto=compress,format&height=${Math.round(cardWidth1)}`} />

              </div>
            </Modal>



            <Modal className="modal fade large_popup delete_popup" show={showDocAlert}>
              <div className="modal-body">
                <button className="close-btn"><span className="material-icons" onClick={e => setShowDocAlert(false)}>close</span></button>
                <span className="material-icons delete-icon">delete_outline</span>
                <h3>Remove</h3>
                <p>This action cannot be undone.</p>
                <p>Are you sure you want to remove {selectedImages?.length > 1 ? "Files" : "File"}</p>
                <div className="popup-footer">
                  <button className="fill_btn" onClick={(e) => handleDeleteAlbumContent()}> Yes, Remove {selectedImages?.length > 1 ? "Them" : "It"}</button>
                </div>
              </div>
            </Modal>

            {selectedImages.length > 0 && (
              <div className="content-footer show">
                <button className="btn_outline gradietn_btn" onClick={() => handleDeletePopup()}>
                  Remove from Album<span className="material-symbols-outlined ms-2 icon">delete</span>
                </button>
                <div className="dropup">
                  {/* <button
              className={`btn gradietn_btn rounded-pill px-3 py-2 mb-lg-0 ${ToggleSwitch ? 'show' : ''}`}
              onClick={() => setToggleSwitch(!ToggleSwitch)}
            >
              Add to Album<span className="material-symbols-outlined ms-2">add</span>
            </button> */}
                  <ul className={`dropdown-menu event-dd ${ToggleSwitch ? 'show' : ''}`}>
                    <li>
                      <a className="dropdown-item"
                        onClick={() => { navigate('/event') }}


                      // onClick={() => handleAddToAlbum('New Album')}

                      >
                        New Album
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    {/* {albumList?.length > 0 &&
                albumList.map((album) => (
                  <li key={album.albumid}>
                    <a className="dropdown-item" onClick={() => handleAddToExistingAlbum(album)}>
                      {album?.albumName}
                    </a>
                  </li>
                ))} */}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
        />
      </div>
    </div>

  );
};

export default ViewAlbum;